import Vue from "vue";
import App from "./App.vue";
import VueRouter from "vue-router";
import { routes } from "./routes";
import store from "./store/store";
import "bootstrap-notify";
import jQuery from "jquery";
import VueToast from "vue-toast-notification";
import "vue-toast-notification/dist/theme-sugar.css";
import VueSweetalert2 from "vue-sweetalert2";
import "sweetalert2/dist/sweetalert2.min.css";
import DGA from "./dga";
import Cookies from "js-cookie";
// import './registerServiceWorker'
// import axios from "@/services/api";
import wallet from "@/services/wallet";
import bonus from "@/services/bonus";

import jsPDF from "jspdf";
import "bootstrap/dist/css/bootstrap.min.css"; // Import Bootstrap CSS (optional)
import "bootstrap-icons/font/bootstrap-icons.css";
const Image = () => import("./components/lazyimage");

//https://www.npmjs.com/package/vue-material-design-icons
//icons at https://materialdesignicons.com/
const ChevronLeftIcon = () =>
  import(
    /* webpackChunkName: "material-icons" */ "vue-material-design-icons/ChevronLeft.vue"
  );
const ChevronRightIcon = () =>
  import(
    /* webpackChunkName: "material-icons" */ "vue-material-design-icons/ChevronRight.vue"
  );
const Account = () =>
  import(
    /* webpackChunkName: "material-icons" */ "vue-material-design-icons/AccountCircle.vue"
  );

Vue.component("arrow-left-icon", ChevronLeftIcon);
Vue.component("arrow-right-icon", ChevronRightIcon);
Vue.component("person-icon", Account);
Vue.component("lazy-image", Image);

const $ = jQuery;
Vue.prototype.jQuery = $;
const EventBus = new Vue();
Vue.prototype.EventBus = EventBus;

// adds pragmatic play games websockets to vue instance
Vue.prototype.DGA = DGA;

Vue.use(VueRouter);
Vue.config.productionTip = true;
Vue.use(VueToast);
Vue.use(VueSweetalert2);

/*function notif(message,type) {

    $.notify({
        icon: "add_alert",
        message: message

    }, {
        type: type,
        timer: 3000,
        placement: {
            from: 'bottom',
            align: 'center'
        }
    });
}*/

Vue.mixin({
  methods: {
    /**
     * gets a random value from 0 to the supplied max
     * @param max
     * @returns {number}
     */
    printFacture: function () {
      var doc = new jsPDF("p", "pt", "a4", true);
      // var specialElementHandlers = {
      //     '#elementH': function (element, renderer) {
      //         console.log(element)
      //         console.log(renderer)
      //         return true;
      //     }
      // };
      doc
        .html(document.getElementById("fracture"), {
          html2canvas: {
            width: 576,
            windowWidth: 576,
            scale: 1, // default is window.devicePixelRatio
          },
          width: 576,
          callback: function (doc) {
            doc.save();
          },
        })
        .then(() => {
          this.setSuccess("Download Complete", "Download Complete");
          this.$router.push({ name: "print", params: {} });
        });

      // Save the PDF
      // doc.save('sample-document.pdf');
    },
    getRandomInt: function (max) {
      return Math.floor(Math.random() * max);
    },
    resetFilters: function () {
      this.$store.dispatch("setHour", -1);
      // this.$store.dispatch("setSportID",1);
      this.$store.dispatch("setMarketID", 0);
      this.$store.dispatch("setCompetitionID", 0);
      this.$store.dispatch("setUpcomingDate", "");
      this.$store.dispatch("setCountryCode", "");
    },
    randomString: function (length) {
      var result = "";
      var characters =
        "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
      var charactersLength = characters.length;
      for (var i = 0; i < length; i++) {
        result += characters.charAt(
          Math.floor(Math.random() * charactersLength)
        );
      }
      return result;
    },

    getClientID: function () {
      var prof = this.getProfile();
      var n = 10;

      if (!prof) {
        return (
          "ui-" +
          Math.floor(Math.random() * (9 * Math.pow(10, n))) +
          Math.pow(10, n)
        );
      }

      n = 5;
      return (
        "ui-" +
        prof.id +
        "-" +
        Math.floor(Math.random() * (9 * Math.pow(10, n))) +
        Math.pow(10, n)
      );
    },
    async uxUpdate(payload) {
      console.log(
        "systemNotification ===> " + JSON.stringify(payload, undefined, 2)
      );

      if (payload.event == "profile_update") {
        let bal = payload.balance;
        this.$store.dispatch("setWalletBalance", bal);

        if (
          payload.message !== null &&
          payload.message.notification !== null &&
          payload.message.notification !== undefined
        ) {
          var notification = payload.message.notification;

          if (notification !== undefined && notification.type !== undefined) {
            var type = notification.type;
            var title = notification.title;
            var msg = notification.message;

            if (type === "error") {
              this.setError(title, msg);
            } else if (type === "success") {
              this.setSuccess(title, msg);
            } else if (type === "warning") {
              this.setWarning(title, msg);
            }
          }
        }
      } else if (payload.event == "bonus_update") {
        let bal = payload.balance;
        this.$store.dispatch("setBonusBalance", bal);

        if (
          payload.message !== null &&
          payload.message.notification !== null &&
          payload.message.notification !== undefined
        ) {
          notification = payload.message.notification;

          if (notification !== undefined && notification.type !== undefined) {
            type = notification.type;
            title = notification.title;
            msg = notification.message;

            if (type === "error") {
              this.setError(title, msg);
            } else if (type === "success") {
              this.setSuccess(title, msg);
            } else if (type === "warning") {
              this.setWarning(title, msg);
            }
          }
        }
      } else if (payload.event_type === "LOGIN") {
        var login_tag = this.getValue("login_tag");
        var current_login_tag = payload.message.login_tag;
        if (parseInt(login_tag) !== parseInt(current_login_tag)) {
          this.setError(
            "Session Expired",
            "Your session on this device has expired"
          );
          this.logout();
        }
      }
    },

    getProfile: function () {
      let prof = this.$store.state.profile;

      if (prof.id === undefined) {
        return false;
      }

      return prof;
    },

    updateProfile: function () {
      var profile = this.getProfile();
      console.log(profile.t);
    },

    setProfile: function (profile) {
      this.$store.dispatch("setProfile", profile);
    },

    setBalance: function (bal) {
      this.$store.dispatch("setWalletBalance", bal);
      Cookies.set("b1", bal, { expires: 2, secure: false });
      this.EventBus.$emit("profile:balance", bal);
    },

    setBonus: function (bal) {
      this.$store.dispatch("setBonusBalance", bal);
      Cookies.set("b2", bal, { expires: 2, secure: false });
      this.EventBus.$emit("profile:balance", bal);
    },

    getBalance: function () {
      var p = Cookies.get("b1");

      if (p) {
        if (p === "undefined") {
          return 0;
        }

        return parseFloat(p);
      }

      return false;
    },

    getBonus: function () {
      var p = Cookies.get("b2");

      if (p) {
        if (p === "undefined") {
          return 0;
        }

        return parseFloat(p);
      }

      return false;
    },

    setAuth: function (auth) {
      Cookies.set("a", auth, { expires: 2, secure: false });
    },

    setCasinoToken: function (auth) {
      Cookies.set("t1", auth, { expires: 2, secure: false });
    },

    getAuth: function () {
      var p = Cookies.get("a");

      if (p) {
        if (p === "undefined") {
          return false;
        }

        try {
          var data = p.trim();
          if (data.length > 0) {
            return data;
          }
        } catch (err) {
          console.log(err.message);
          return false;
        }
      }

      return false;
    },

    getCasinoToken: function () {
      var p = Cookies.get("t1");

      if (p) {
        if (p === "undefined") {
          return "DEMO";
        }

        try {
          var data = p.trim();
          if (data.length > 0) {
            return data;
          }
        } catch (err) {
          console.log(err.message);
          return "DEMO";
        }
      }

      return "DEMO";
    },

    removeProfile: function () {
      Cookies.remove("i");
    },

    removeAuth: function () {
      Cookies.remove("a");
      Cookies.remove("t1");
    },

    reloadProfile: function () {
      var profile = this.getProfile();
      var vm = this;

      if (profile === false || profile === undefined) {
        return;
      }

      vm.refreshBalance();
      vm.refreshBonus();
    },

    refreshBalance: function () {
      var profile = this.getProfile();
      var vm = this;

      if (profile === false || profile === undefined) {
        return;
      }

      var path = "/balance";

      wallet
        .get(path, {
          headers: {
            "api-key": vm.getAuth(),
          },
        })
        .then((res) => {
          vm.setBalance(parseFloat(res.data.b1));
        })
        .catch((err) => {
          if (err.response) {
            if (
              parseInt(err.response.status) === 401 ||
              parseInt(err.response.status) === 400 ||
              parseInt(err.response.status) === 428
            ) {
              vm.setError(
                "Session Expired",
                "Your session on this device has expired"
              );
              vm.logout();
              return;
            } else {
              vm.setError("Failed", err.response.data.message);
            }
          } else if (err.request) {
            console.log(JSON.stringify(err.request));
          } else {
            console.log(JSON.stringify(err));
          }
        });
    },

    refreshBonus: function () {
      var profile = this.getProfile();
      var vm = this;

      if (profile === false || profile === undefined) {
        return;
      }

      var path = "/bonus/balance";

      bonus
        .get(path, {
          headers: {
            "api-key": vm.getAuth(),
          },
        })
        .then((res) => {
          vm.setBonus(parseFloat(res.data.balance));
        })
        .catch((err) => {
          if (err.response) {
            if (
              parseInt(err.response.status) === 401 ||
              parseInt(err.response.status) === 400 ||
              parseInt(err.response.status) === 428
            ) {
              // vm.setError("Session Expired", "Your session on this device has expired");
              // vm.logout();
              return;
            } else {
              vm.setError("Failed", err.response.data.message);
            }
          } else if (err.request) {
            console.log(JSON.stringify(err.request));
          } else {
            console.log(JSON.stringify(err));
          }
        });
    },

    isMobile: function () {
      return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
        navigator.userAgent
      );
    },
    isDesktop: function () {
      return !/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
        navigator.userAgent
      );
    },

    formatCurrency: function (num) {
      if (
        num === false ||
        isNaN(parseFloat(num)) ||
        isNaN(num) ||
        num === undefined ||
        num === "" ||
        num === null
      ) {
        return 0;
      }

      num = parseFloat(num).toFixed(2);
      var num_parts = num.toString().split(".");
      num_parts[0] = num_parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      return num_parts.join(".");
    },

    showMenu: function () {
      document.getElementById("menu-init").click();

      this.bestlip_visible = true;
    },
    copyToClipboard: function (text) {
      if (window.clipboardData && window.clipboardData.setData) {
        // IE specific code path to prevent textarea being shown while dialog is visible.
        return window.clipboardData.setData("Text", text);
      } else if (
        document.queryCommandSupported &&
        document.queryCommandSupported("copy")
      ) {
        var textarea = document.createElement("textarea");
        textarea.textContent = text;
        textarea.style.position = "fixed"; // Prevent scrolling to bottom of page in MS Edge.
        document.body.appendChild(textarea);
        textarea.select();
        try {
          return document.execCommand("copy"); // Security exception may be thrown by some browsers.
        } catch (ex) {
          console.warn("Copy to clipboard failed.", ex);
          return false;
        } finally {
          document.body.removeChild(textarea);
        }
      }
    },
    toTop: function () {
      window.scrollTo(0, 0);
    },
    getURL: function (path) {
      if (path.length > 0) {
        if (path.substring(0, 1) == "/") {
          // remove the first /
          path = path.substring(1);
        }
      }

      return process.env.VUE_APP_ASSETS_BASE_URL + path;
    },
    saveObject: function (key, value) {
      value = JSON.stringify(value);

      if (typeof Storage !== "undefined") {
        localStorage.setItem(key, value);
      } else {
        document.cookie = key + "=" + value;
      }
    },
    setValue: function (key, value) {
      if (typeof Storage !== "undefined") {
        localStorage.setItem(key, value);
      } else {
        document.cookie = key + "=" + value;
      }
    },
    getObject: function (key) {
      if (typeof Storage !== "undefined") {
        var post = localStorage.getItem(key);

        if (post === undefined) {
          return false;
        }

        return JSON.parse(post);
      } else {
        return JSON.parse(this.getCookie(key));
      }
    },

    shortenName: function (name, characters) {
      if (name == undefined) {
        return name;
      }

      name = name.trim();

      if (name.length <= characters) {
        return name;
      }

      return name.substring(0, characters) + "...";
    },
    shortenMsisdn: function (name) {
      return "****" + name.slice(name.length - 4);
    },

    getValue: function (key) {
      if (typeof Storage !== "undefined") {
        var post = localStorage.getItem(key);
        if (post == "undefined") {
          return "";
        }

        return post;
      } else {
        return this.getCookie(key);
      }
    },

    removeObject: function (key) {
      if (typeof Storage !== "undefined") {
        localStorage.removeItem(key);
      } else {
        document.cookie = key + "=;expires=Thu, 01 Jan 1970 00:00:01 GMT;";
      }
    },

    getCookie: function (ckey) {
      var key = ckey + "=";
      var decodedCookie = decodeURIComponent(document.cookie);
      var ca = decodedCookie.split(";");
      for (var i = 0; i < ca.length; i++) {
        var c = ca[i];
        while (c.charAt(0) == " ") {
          c = c.substring(1);
        }
        if (c.indexOf(key) == 0) {
          return c.substring(key.length, c.length);
        }
      }
      return "";
    },

    goBack: function () {
      this.$router.back();
    },

    goHome: function () {
      this.reloadProfile();
      window.location.href = "/";
    },
    goDeposit: function () {
      this.goTo("deposit");
    },

    setError: function (title, body) {
      Vue.$toast.clear();
      Vue.$toast.open({
        message: body,
        type: "error",
        duration: 5000,
        dismissible: true,
        position: "bottom",
        // all of other options may go here
      });

      //this.notify(body,'danger');
    },

    setWarning: function (title, body) {
      Vue.$toast.open({
        message: body,
        type: "warning",
        duration: 5000,
        dismissible: true,
        position: "bottom",
        // all of other options may go here
      });

      //this.notify(body,'danger');
    },

    setSuccess: function (title, body) {
      Vue.$toast.open({
        message: body,
        type: "success",
        duration: 5000,
        dismissible: true,
        position: "bottom",
        // all of other options may go here
      });

      //this.notify(body,'success');
    },

    reset: function () {
      this.$store.dispatch("resetAlerts");
    },

    logout: function () {
      this.$store.dispatch("setProfile", {});
      document.getElementById("menu-modal").style.display = "none";

      window.localStorage.clear();

      this.closeMenu();
      // set profile to false
      this.removeProfile();
      this.reset();
      // if(this.$route.path !== '/'){
      //     this.$router.push({name: 'home', params: {}});
      // }

      let ar = window.location.href.split("/");
      let page = ar[ar.length - 1];

      if (page.length !== 0 || this.$route.path !== "/")
        window.location.href = "/";
    },

    getURLParameters: function () {
      var query = window.location.search.substring(1);

      var vars = query.split("&");

      var query_string = {};

      for (var i = 0; i < vars.length; i++) {
        var pair = vars[i].split("=");
        // If first entry with this name
        if (typeof query_string[pair[0]] === "undefined") {
          query_string[pair[0]] = decodeURIComponent(pair[1]);
          // If second entry with this name
        } else if (typeof query_string[pair[0]] === "string") {
          var arr = [query_string[pair[0]], decodeURIComponent(pair[1])];
          query_string[pair[0]] = arr;
          // If third or later entry with this name
        } else {
          query_string[pair[0]].push(decodeURIComponent(pair[1]));
        }
      }

      return query_string;
    },
    closeWidget: function () {
      document.getElementById("dpo-widg").style.display = "none";
    },
    closeBanner: function () {
      this.$store.dispatch("setAppBanner", false);
      document.getElementById("mobilebanner").style.display = "none";
    },

    goTo: function (Name) {
      try {
        //this.dismiss();
        this.closeMenu();

        Name = Name.toLowerCase();

        if (this.$store.state.current_page === Name) return;

        this.$router.push({ name: Name });
      } catch (e) {
        console.log(e.message);
      }
    },
    sortObject: function (obj) {
      return Object.keys(obj)
        .sort(function (a, b) {
          return parseFloat(a) - parseFloat(b);
        })
        .reduce(function (result, key) {
          result[key] = obj[key];
          return result;
        }, {});
    },
    //notify: function(message, type) {

    //no-undef
    //notif(message,type);
    //},
    closeMenu: function () {
      var el = document.getElementById("menu-close");
      if (el) {
        el.click();
      }
    },
  },
});
const scrollBehavior = (to, from, savedPosition) => {
  return (
    savedPosition || {
      top: to.meta?.scrollTop || 0,
      left: 0,
    }
  );
};
const router = new VueRouter({
  mode: "history",
  routes,
  scrollBehavior,
});

/*
// This callback runs before every route change, including on page load.
router.beforeEach((to, from, next) => {

    // window.scrollTo(0, 0);
    
    // This goes through the matched routes from last to first, finding the closest route with a title.
    // e.g., if we have `/some/deep/nested/route` and `/some`, `/deep`, and `/nested` have titles,
    // `/nested`'s will be chosen.
    const nearestWithTitle = to.matched.slice().reverse().find(r => r.meta && r.meta.title);

    // Find the nearest route element with meta tags.
    const nearestWithMeta = to.matched.slice().reverse().find(r => r.meta && r.meta.metaTags);

    // If a route with a title was found, set the document (page) title to that value.
    if (nearestWithTitle) document.title = nearestWithTitle.meta.title;

    // Remove any stale meta tags from the document using the key attribute we set below.
    Array.from(document.querySelectorAll('[data-vue-router-controlled]')).map(el => el.parentNode.removeChild(el));

    // Skip rendering meta tags if there are none.
    if (!nearestWithMeta) return next();

    // Turn the meta tag definitions into actual elements in the head.
    nearestWithMeta.meta.metaTags.map(tagDef => {
        const tag = document.createElement('meta');

        Object.keys(tagDef).forEach(key => {
            tag.setAttribute(key, tagDef[key]);
        });

        // We use this to track which meta tags we create so we don't interfere with other ones.
        tag.setAttribute('data-vue-router-controlled', '');

        return tag;
    })
        // Add the meta tags to the document head.
        .forEach(tag => document.head.appendChild(tag));

    next();
});
*/

var v = new Vue({
  router,
  store,
  render: (h) => h(App),
  mounted: () => document.dispatchEvent(new Event("x-app-rendered")),
}).$mount("#app");

if (!v) {
  window.location.href = "https://lite.Powerbet.co.ke";
}
